import React, { useState, useEffect } from 'react';
import { useHistory  } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ClearIcon from "@mui/icons-material/Clear";
import { AppBar, Toolbar, Grid, Divider, Typography, IconButton, Button, Snackbar } from "@material-ui/core";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export default function LatticeStatusBar(props) {
    const history = useHistory(); 
    const classes = {
        root: {
            backgroundColor: "transparent",
        },
        jobDetailLabel: {
            fontSize: 12,
            fontWeight: 500,
        },
    }  
      
    const [isEdit, setIsEdit] = useState(props.isEdit);
    const [copySuccess, setCopySuccess] = useState(false);
    const [isLattice, setIsLattice] = useState(props.isLattice);

    useEffect(() => {
        setIsEdit(props.isEdit);
        setIsLattice(props.isLattice);
    }, [props.isEdit, props.isLattice]);

    const copyToClipboard = () => {
        if (props.order && props.order.trackURL) {
            navigator.clipboard.writeText(props.order.trackURL).then(() => {
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000);
            }, (err) => {
                console.error('Could not copy text: ', err);
            });
        }
    };
    
    const renderDatetimePicker = (name, value, setStateFunc, setUpdatingFunc, changeRate) => {               
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker 
                    name={name} 
                    className="editableJobField" 
                    fullWidth
                    format="yyyy/MM/dd HH:mm"  
                    value={value}
                    onChange={(input) => {
                        setUpdatingFunc(prevState => ({
                            ...prevState,
                            [name]: input,
                        }));
                        setStateFunc(input); 
                    }}
                />
            </MuiPickersUtilsProvider>
        )
    };  

    const renderDateTimeEdit = (label, name, val, setFunc, setUpdatingFunc, size1, size2, size3, changeRate) => {
      return (
          <Grid item container direction="row" sm={12} spacing={1}>
              <Grid item sm={size1} container justifyContent="flex-end">
                 <Typography variant="button" color="primary" style={classes.jobDetailLabel}>{label}</Typography>                       
              </Grid>
              <Grid item sm={size2} container justifyContent="flex-start">
                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker 
                          name={name} 
                          className="editableJobField" 
                          fullWidth
                          format="yyyy/MM/dd HH:mm"  
                          value={val}
                          onChange={(input) => {
                              console.log(`${name} changed to:`, input);
                              setUpdatingFunc(prevState => {
                                  const newState = {
                                      ...prevState,
                                      [name]: input,
                                  };
                                  console.log(`New updating state for ${name}:`, newState);
                                  return newState;
                              });
                              setFunc(input); 
                              // Removed: handleTimeChange(name);
                          }}
                      />
                    </MuiPickersUtilsProvider>
              </Grid>  
              <Grid item sm={size3}></Grid>  
          </Grid>
      );
    }

    const handleTimeChange = (field) => {
        // List of fields that should trigger rate adjustment
        const rateAdjustmentFields = ['dtCallIn', 'pickupBy', 'dontDeliveryBefore', 'dtDCT'];
        
        if (rateAdjustmentFields.includes(field)) {
          props.setRateAdjustmentDialog({
            isOpen: true,
            changeRate: true,
            field: field
          });
        } else {
          // For fields that don't affect rate, directly update without showing the dialog
          props.updateOrder();
        }
      };

    return (
        <AppBar position="static" elevation={0} style={{backgroundColor: "#0D191E", overflow: "hidden", paddingLeft: "20px", marginTop: "1px", display: "flex", alignItems: "center",}}>
            <Toolbar className={"subheaderJobDetail"} disableGutters={true} style={{ alignItems: "center", width: "100%" }}>
                {props.order && (
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={0} style={{ width: "100%", backgroundColor: "#0D191E" }}>
                        <Grid container item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div style={{ padding: "10px", display: "flex", alignItems: "center" }}>
                                <img src={props.companyLogo} alt={props.order?.ShipperName} style={{maxWidth: "180px", width: "100%", height: "auto", display: "block", borderRadius: "8px"}}/>
                            </div>
                        </Grid>
                        <Grid container spacing={0} item xs={11}
                            style={{
                                borderRadius: "6px",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                boxShadow: "0px 0px 10px #000000",
                                borderColor: props.order.statusBarColor !== null ? props.order.statusBarColor :
                                    props.order.exception !== null && props.order.exception ? "#DE3562" :
                                    props.order.Status < 8 ? "#4331A4" :
                                    props.order.Status >= 8 && props.order.Status < 32 ? "#007E82" :
                                    props.order.Status === 32 ? "#F78133" :
                                    "#485054",
                                backgroundColor: props.order.statusBarColor !== null ? props.order.statusBarColor :
                                    props.order.exception !== null && props.order.exception ? "#DE3562" :
                                    props.order.Status < 8 ? "#4331A4" :
                                    props.order.Status >= 8 && props.order.Status < 32 ? "#007E82" :
                                    props.order.Status === 32 ? "#F78133" :
                                    "#485054",
                                display: "flex",
                                alignItems: "center",
                                height: '160px'
                            }}
                        >
                        <Grid container direction='row' style={{ paddingLeft: "10px" }}>
                            <Grid item xs={3} xl={2} style={{...classes.toolbarGridItem, display: "flex", flexDirection: "column", justifyContent: "center", paddingTop:"10px"}}>
                                <Grid item xs={12} direction='column' style={{ paddingLeft: "10px" }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" color="textSecondary"> 
                                            Job Id: <strong>{props.jobId}</strong> 
                                        </Typography>
                                    </Grid>     
                                    <Grid item xs={12} style={{ paddingBottom: "15px" }}>
                                        <Button size="small" disabled={!props.order?.priorJobID}
                                            onClick={() => { window.location.href = `/jobDetail/${props.order.priorJobID}`}}>
                                            Prev
                                        </Button>
                                        <Button size="small" disabled={!props.order?.NextJobID}
                                            onClick={() => { window.location.href = `/jobDetail/${props.order.NextJobID}`}}>
                                            Next
                                        </Button>
                                        <Button size="small" disabled={!props.order?.parentJobID}
                                            onClick={() => { window.location.href = `/jobDetail/${props.order.parentJobID}`}}>
                                            Parent
                                        </Button>          
                                    </Grid> 
                                    <Grid item xs={12}>
                                        <Typography variant="h7" color="textSecondary"> Tracking: <strong>{props.order?.TrackNumber}</strong> </Typography>
                                    </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary" startIcon={<ContentCopyIcon />}
                                                disabled={!props.order?.trackURL}
                                                style={{ minHeight: 35, opacity: props.order?.trackURL ? 1 : 0.5, cursor: props.order?.trackURL ? 'pointer' : 'not-allowed' }}
                                                onClick={copyToClipboard}>
                                                Copy Tracking URL
                                            </Button>
                                    </Grid>
                                </Grid>  
                            </Grid>
                            <Grid item xs={1} spacing={3} style={{ display: "flex", alignItems: "center", justifyContent: "left"}}>                  
                                <img src={props.order.statusIconURL} style={{maxWidth: "100%", maxHeight: '60%', borderRadius: "8px",}}/>
                            </Grid>

                            <Grid container xs={6} xl={6}  >
                            <Grid container style={{ alignItems: "center", paddingLeft:'10px' }}>
                                <Grid item xs={6} >
                                    {!isEdit ?
                                        <Grid item xs={12}  style={{...classes.toolbarGridItem, display: "flex", flexDirection: "column"}}>
                                            <Typography variant="h6" color="textSecondary">{props.order?.headerTitle1}</Typography>
                                            <Typography variant="h6" color="textPrimary">{props.order?.headerLine1}</Typography>
                                            {props.order?.headerLine2 !== "" && <Typography variant="body1" color="textPrimary">{props.order?.headerLine2}</Typography>}
                                        </Grid> :
                                        <Grid item xs={12} style={{display: "flex", flexDirection: "column"}}>
                                            {renderDateTimeEdit("Ready At", "dtCallIn", props.orderDTCallIn, props.setOrderDTCallIn, props.setUpdatingItem, 2, 6, 4, true)} 
                                            {renderDateTimeEdit("Pickup By", "pickupBy", props.dtPickupBy, props.setDTPickupBy, props.setUpdatingItem, 2, 6, 4, true)}   
                                            {renderDateTimeEdit("ETA", "eta", props.orderSCT, props.setOrderSCT, props.setUpdatingItem, 2, 6, 4, false)} 
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    {!isEdit ?
                                        <Grid item xs={12} style={{...classes.toolbarGridItem, display: "flex", flexDirection: "column", justifyContent: "center", paddingRight: "10px"}}>
                                            <Typography variant="h6" color="textSecondary">{props.order?.headerTitle2}</Typography>
                                            <Typography variant="h6" color="textPrimary"><strong>{props.order?.headerLine3}</strong></Typography>
                                            {props.order?.headerLine4 !== "" && <Typography variant="body1" color="textPrimary">{props.order?.headerLine4}</Typography>}
                                        </Grid> :
                                        <Grid item xs={12} style={{display: "flex", flexDirection: "column"}}>
                                            {renderDateTimeEdit("Don't Deliver Before", "dontDeliveryBefore", props.dtDontDeliveryBefore, props.setDTDontDeliveryBefore, props.setUpdatingItem, 4, 6, 2, true)}
                                            {renderDateTimeEdit("Deliver By", "dtDCT", props.orderDTDCT, props.setOrderDTDCT, props.setUpdatingItem, 4, 6, 2, true)}                   
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            </Grid>

                            {isLattice && 
                            <Grid item xs={1} style={{...classes.toolbarGridItem, display: "flex", alignItems: "center", justifyContent: "center"}}>                  
                                <img src={props.order.timeIconURL} style={{maxWidth: "100%", maxHeight: '60%'}}/>
                            </Grid> 
                            }
                            
                            {!isEdit ? <Grid item container xs={1} xl={2}></Grid> : <Grid item container xs={1} xl={2}></Grid>} 
                            
                        </Grid>
                    </Grid>
                </Grid>
                )}
            </Toolbar>
            {props.order.indicatorColor !== null && <Divider sx={{ bgcolor: props.order.indicatorColor, borderBottomWidth: 10 }} />}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={copySuccess}
                autoHideDuration={2000}
                message="Tracking URL copied to clipboard"
            />
        </AppBar>       
    );
}