import React from "react";
import "date-fns";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import HttpRequest from "../../HttpService.js";
import CustomDialog from "../components/dialog";
import NoticeEmitter from "../../NoticeUtil";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import 'dayjs/locale/zh-cn';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import 'moment/locale/sv';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import sv from 'date-fns/locale/sv';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Snackbar, Alert } from "@mui/material";
import Tooltip from '@material-ui/core/Tooltip';

export default function TripFunctionDialog(props) {
  const parameters = props.tripFunction.parameters;
  //console.log("TripFunctionDialog============" + JSON.stringify(parameters));
  const [inputs, setInputs] = React.useState({});
  const [overrideOpenDialog, setOverrideOpenDialog] = React.useState(false);
  const [overrideDialogMessage, setOverrideDialogMessage] = React.useState("");
  const [processedParameters, setProcessedParameters] = React.useState([]);

  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState("");

  const autoDatetimeFunctions = [
    'mark delivered',
    'mark complete',
    'mark picked up'
    // Add other function names that should auto-set time
  ];

  React.useEffect(() => {
    if (parameters && parameters.length > 0) {
      let updatedParams = [...parameters];
      
      // Handle lat/long parameters
      const latLongParam = updatedParams.find(p => p.label === 'latLongString');
      if (latLongParam && latLongParam.value) {
        const [lat, lng] = latLongParam.value.split(',').map(coord => parseFloat(coord.trim()));
        
        updatedParams = updatedParams.map(param => {
          if (param.label === 'lat' && !isNaN(lat)) {
            param.value = lat.toFixed(6);
            param.default = lat.toFixed(6);
          }
          if (param.label === 'long' && !isNaN(lng)) {
            param.value = lng.toFixed(6);
            param.default = lng.toFixed(6);
          }
          return param;
        });
      }
  
      // Auto-set current datetime for specific functions
      if (autoDatetimeFunctions.includes(props.tripFunction.functionText?.toLowerCase())) {
        updatedParams = updatedParams.map(param => {
          if (param.type === 'datetime') {
            param.value = new Date();
            param.default = new Date();
          }
          return param;
        });
      }
      
      setProcessedParameters(updatedParams);
    }
  }, [parameters, props.tripFunction.functionText]);

  const overrideFunction = () => {
    let paramsList = parameters;
    paramsList.push({
      label: "override",
      prompt: "",
      type: "bit",
      length: 1,
      mo: "override",
      value: 1,
    });
    console.log(props.tripFunction.procedureName + "----overrideFunction --------" + JSON.stringify(paramsList));
    HttpRequest.doTripFunction(
      props.tripFunction.accessToken,
      props.tripFunction.jobId,
      paramsList,
      props.tripFunction.procedureName
    ).then(
      function successCallback(body) {
        console.log("procedure is done " + JSON.stringify(body.data));
        setMessageInfo(
          props.tripFunction.procedureName + " procedure is done!"
        );
        setOpenSnack(true);
        if (props.tripFunction.refreshAfter)
          NoticeEmitter.emit("refreshJobDetail", "");
        setOverrideOpenDialog(false);
        props.handleDialogClose(); // Close the main dialog
      },
      function errorCallback(err) {
        alert("Failed to finish procedure " + props.tripFunction.procedureName + ". Error is : " + err);
        setOverrideOpenDialog(false);
      }
    );
  };

  const handleConfirm = () => {
    console.log("Parameters before submit:", processedParameters);
    processedParameters.forEach((item) => { 
      console.log("TripFunctionDialog====params item===" + JSON.stringify(item));
      if(item.type === "dropdown" && item.value === ""){    
        item.value = item.default;     
      }
    });  
    
    console.log("TripFunctionDialog====parameters========" + JSON.stringify(processedParameters));

    console.log("Sending to backend:", {
      accessToken: props.tripFunction.accessToken,
      jobId: props.tripFunction.jobId,
      params: processedParameters,
      procedure: props.tripFunction.procedureName
    });
  
    HttpRequest.doTripFunction(
      props.tripFunction.accessToken,
      props.tripFunction.jobId,
      processedParameters,  // Use processedParameters here
      props.tripFunction.procedureName
    ).then(
      function successCallback(body) {
        console.log("procedure is done " + JSON.stringify(body.data));
        if (body.data && body.data.length > 0) {
          var res = body.data[0];
          if (res.ResultCode === 10) {
            setOverrideDialogMessage(res.Result);
            setOverrideOpenDialog(true);
          } else {
            const message = res.Result ? 
              `${props.tripFunction.procedureName} result is: ${res.Result}` : 
              `${props.tripFunction.procedureName} procedure is done!`;
            
            props.handleDialogClose();
            if (props.onOperationComplete) {
              props.onOperationComplete(message); // Pass message to parent
            }
          }
        } else {
          const message = `${props.tripFunction.procedureName} procedure is done!`;
          console.log("About to call onOperationComplete with message:", message);
          props.handleDialogClose();
          if (props.onOperationComplete) {
            props.onOperationComplete(message); // Pass message to parent
          }
        }
        if (props.tripFunction.refreshAfter)
          NoticeEmitter.emit("refreshJobDetail", "");
      },
      function errorCallback(err) {
        alert(
          "Failed to finish procedure " +
            props.tripFunction.procedureName +
            " Error is : " +
            err
        );
        props.handleDialogClose();
      }
    );
  };

  const handleOperationComplete = (result) => {
    if (result) {
      setMessageInfo(
        props.tripFunction.procedureName +
          " result is: " +
          result
      );
    } else {
      setMessageInfo(
        props.tripFunction.procedureName + " procedure is done!"
      );
    }
    setOpenSnack(true);
    if (props.tripFunction.refreshAfter)
      NoticeEmitter.emit("refreshJobDetail", "");
    props.handleDialogClose();
    // Notify parent component that operation is complete
    if (props.onOperationComplete) {
      props.onOperationComplete();
    }
  };

  const renderElements = (item, index) => {
    const params = item;
    //console.log("TripFunctionDialog renderElements --------" + JSON.stringify(item));

    if (params.type === "integer") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          fullWidth
          margin="dense"
          key={params.label}
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });
            parameters.forEach((row) => {
              if (row.label === params.label) {
                row.value = input.target.value;
              }
            });
          }}
        />
        </Tooltip>
      );
    } else if (params.type === "varchar") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          fullWidth
          key={params.label}
          margin="dense"
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });
            parameters.forEach((row) => {
              if (row.label === params.label) {
                row.value = input.target.value;
              }
            });
          }}
        />
        </Tooltip>
      );
    } else if (params.type === "dropdown") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          variant="outlined"
          fullWidth
          key={params.label}
          margin="dense"
          label={params.prompt}
          select
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });
            parameters.forEach((row) => {
              if (row.label === params.label) {
                row.value = input.target.value;
              }
            });
          }}
        >
          {params.list.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        </Tooltip>
      );
    } else if (params.type === "datetime") {
      return (      
        <Tooltip title={params.mo} placement="top-end" arrow>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
            <DateTimePicker
              renderInput={(props) => (
                <TextField autoFocus={index === 0} {...props} />
              )}
              label={params.prompt}
              ampm={false}
              value={params.value || new Date()}
              onChange={(input) => {
                setInputs((prevState) => ({
                  ...prevState,
                  [params.label]: input,
                }));
                setProcessedParameters(prevParams => 
                  prevParams.map(p => 
                    p.label === params.label ? {...p, value: input} : p
                  )
                );
              }}
            />
          </LocalizationProvider>
        </Tooltip>
      );
    } else if (params.type === "decimal") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
          <TextField
            autoFocus={index === 0 ? true : false}
            variant="outlined"
            fullWidth
            key={params.label}
            margin="dense"
            label={params.prompt}
            value={params.value || params.default || ''}
            onChange={(input) => {
              const newValue = input.target.value;
              // Update both processedParameters and original parameters
              setProcessedParameters(prev => 
                prev.map(p => p.label === params.label ? {...p, value: newValue} : p)
              );
              parameters.forEach((row) => {
                if (row.label === params.label) {
                  row.value = newValue;
                }
              });
            }}
          />
        </Tooltip>
      );
    } else if (params.type === "checkbox") {
      const defaultValue = params.default ?? true;
      
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  label={params.prompt}
                  defaultChecked={defaultValue}
                  color="primary"  
                  onChange={(event) => {

                    console.log("Checkbox change:", {
                      label: params.label,
                      newValue: event.target.checked,
                      currentParams: processedParameters
                    });

                    const newValue = event.target.checked;
                    setInputs(prev => ({
                      ...prev,
                      [params.label]: newValue,
                    }));
                    setProcessedParameters(prev => 
                      prev.map(p => p.label === params.label ? 
                        {...p, value: newValue} : p
                    ));
                    // Update original parameters too
                    parameters.forEach(row => {
                      if (row.label === params.label) {
                        row.value = newValue;
                      }
                    });
                  }}
                />
              }
              label={
                <Typography style={{ color: "black" }}>
                  {params.prompt}
                </Typography>
              }
            />
          </FormControl>
        </Tooltip>
      );
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        className="darkDialog"
        open={props.open}
        onClose={() => props.handleDialogClose()}
      >
        <DialogTitle>{props.tripFunction.functionText}</DialogTitle>
        <DialogContent>
          {processedParameters.map((item, index) => renderElements(item, index))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>
            <CheckIcon></CheckIcon> Confirm
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setInputs({});
              props.handleDialogClose();
            }}
          >
            <ClearIcon></ClearIcon> Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CustomDialog
        open={overrideOpenDialog}
        onClose={() => setOverrideOpenDialog(false)}
        hasCloseBtn={true}
        dialogMessage={overrideDialogMessage}
        dialogTitle="Warning"
        dialogAction={() => {
          overrideFunction();
        }}
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnack(false);
          }}
          severity="info"
          sx={{ width: "100%" }}
        >
          {messageInfo}
        </Alert>
      </Snackbar>
    </div>
  );
}