import React, { useState, useEffect } from "react";
import DataTable from "./deDataGrid";
import SideButtons from "./sideButtons";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "../Copyright";
import DateFnsUtils from "@date-io/date-fns";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Grid,Button } from "@material-ui/core";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import NoticeEmitter from "../NoticeUtil.js";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/ClearAll";
import SaveIcon from "@mui/icons-material/SaveAlt";
import MapIcon from "@mui/icons-material/Map";
import RefreshIcon from "@mui/icons-material/Refresh";
import ToggleDataGrid from "./ToggleDataGrid.js";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import HttpRequest from "../HttpService";

// import Drawer from '@mui/material/Drawer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  Text: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 10,
    },
    fontSize: 14,
  },
  dropDownFormControl: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  invisible: {
    display: "none",
  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function Search(props) {
  const classes = useStyles();
  const [localSearchParams, setLocalSearchParams] = useState({
    account: "",
    order: "",
    billing: "",
    caller: "",
    addressID: "",
    serviceArea: "",
    serviceTime: "",
    drNum: "",
    shipperToFrom: "",
    phone: "",
    name: "",
    date: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    searchByRange: 3,
    driverSearchType: "any",
    location: -1,
    status: -1,
    activeTrip: true,
    exception: false,
    company: 1,
    daysBack: 9,
  });

  const [preDefinedOptions, setPreDefinedOptions] = useState([]);
  const [trips, setTrips] = useState([]);
  const [selection, setSelection] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [startedSearch, setStartedSearch] = useState(false);

  const [defaultChecked, setDefaultChecked] = useState(true);
  const [dispatchChecked, setDispatchChecked] = useState(false);
  const [verficationChecked, setVerificationChecked] = useState(false);

  const [showIndicator, setShowIndicator] = useState(false);

  const [stateReset, setStateReset] = useState(false);
  const [searchType, setSearchType] = useState("standard");
  const [jobIDList, setJobIDList] = useState("");
  const [statusDDList, setStatusDDList] = useState(null);
  const [locationDDList, setLocationDDList] = useState(null);
  const driverSearchType = [{value: "first", label:"First Name"},{value: "last", label:"Last Name"},{value: "any", label:"Any"}];
 
  const [referenceList, setReferenceList] = useState("");
  const [referenceAccount, setReferenceAccount] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      document.title = "Search";
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    console.log("localstorage statusDD", localStorage.getItem("statusDDList"));
    console.log("localstorage localDD", localStorage.getItem("locationDDList"));
    let token = localStorage.getItem("access_token");
    if (token === undefined || token === null) window.location.href = "/";
    try {
     
      HttpRequest.getSearchPreDefined(token).then(async function (body) {        
        let sList = body.data.map((option, index) => {
          option.id = index;
          return option;
        });    
        setPreDefinedOptions(sList);
      });
    
      HttpRequest.getTripListFunctions(token).then(async function (body) {        
        let buttonList = body.data.map((button, index) => {
          button.id = index;
          return button;
        });    
        setButtons(buttonList);
      });

      HttpRequest.getStatusDD(token).then(async function (body) {    
        //console.log("getStatusDD", JSON.stringify(body.data));   
        let statusList = body.data.map((item) => {         
          return {value:item.status, label:item.descr};
         });    
         statusList.unshift( {value:-1, label:"ALL"});
         setStatusDDList(statusList);        
         localStorage.setItem("statusDDList", JSON.stringify(statusList)); 
      });

      HttpRequest.getLocationDD(token).then(async function (body) {    
        console.log("getLocationDD",JSON.stringify(body.data));    
        let locList = body.data.map((item) => {         
          return {value:item.location, label:item.descr};
         });   
         locList.unshift( {value:-1, label:"ALL"});
         setLocationDDList(locList);   
         console.log("getLocationDD",JSON.stringify(locList));       
         localStorage.setItem("locationDDList", JSON.stringify(locList)); 
      });

      var retrievedSearchParams = localStorage.getItem("searchParameter");
      if (retrievedSearchParams !== "") {
        var searchParams = JSON.parse(retrievedSearchParams);
        console.log("retrieved search Parameter is " + JSON.stringify(searchParams));
        if (searchParams) {
          setLocalSearchParams(searchParams);
          queryTrips(searchParams);
        } else queryTrips(localSearchParams);
      } else queryTrips(localSearchParams);
     
    } catch (e) {
      console.log("search page exception: " + e);
    }
  }, []);

  const row1 = [
    ["Account", "account"],
    ["Order ID", "order"],
    ["Billing Reference", "billing"],
    ["Caller", "caller"],    
    ["Service Area", "serviceArea"],
    ["Service Time", "serviceTime"],
    ["Driver #", "drNum"],
  ];

  const row2 = [
    ["Shipper to From", "shipperToFrom"],
    ["AddressID", "addressID"],
    ["Contact Phone", "phone"],
    ["Contact Name", "name"],
  ];

  const saveSearchQuery = () => {
    console.log("postUserSettings-" + JSON.stringify(localSearchParams));
    HttpRequest.postUserSettings(localStorage.getItem("access_token"),"000",JSON.stringify(localSearchParams)).then((res) => {
      console.log("postUserSettings" + JSON.stringify(res.data));
    });
  };

  const showMap = () => {
    localStorage.setItem("orderListInMap", JSON.stringify(selection));
    let inxURL = "/orderListMap";
    window.open(inxURL, "_blank");
  };

  const handleSearch = () => {
    if (searchType === "standard") {
      queryTrips(localSearchParams);
    } else if (searchType === "jobIdList") {
      jobIDListSearch();
    } else if (searchType === "referenceList") {
      referenceListSearch();
    }
  };
  
  const referenceListSearch = () => {
    if (referenceList.length < 2) return;
    setShowIndicator(true);
    
    const content = {
      referenceList: referenceList,
      account: referenceAccount
    };
    
    console.log("Reference List Search - content:", content);
    
    HttpRequest.referenceListSearch(localStorage.getItem("access_token"), content)
      .then(async function (body) {
        console.log("Reference List Search result:", JSON.stringify(body.data));
        let trips = body.data.map((trip, index) => {
          trip.id = index;
          trip.dtCallIn = Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm");
          return trip;
        });
        setTrips(trips);
        setStartedSearch(true);
        setShowIndicator(false);
      })
      .catch(error => {
        console.error("Reference List Search error:", error);
        setShowIndicator(false);
      });
  };

  const jobIDListSearch = () =>{
    if(jobIDList.length < 2) return;    
    setShowIndicator(true);
    console.log("jobIDList TripSearch jobIDList-" + jobIDList)
    HttpRequest.jobIDListSearch(localStorage.getItem("access_token"), jobIDList).then(async function (body) {
      console.log("jobIDList TripSearch result-" + JSON.stringify(body.data));     
      let trips = body.data.map((trip, index) => {
        trip.id = index;
        trip.dtCallIn = Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm");
        return trip;
      });    
      setTrips(trips);
      setStartedSearch(true);
      setShowIndicator(false);
    });
  }

  const clearSearch = () => {
    if(searchType === "standard") {
      const defaultParams = {
        account: "",
        order: "",
        billing: "",
        caller: "",
        addressID: "",
        serviceArea: "",
        serviceTime: "",
        drNum: "",
        shipperToFrom: "",
        phone: "",
        name: "",
        date: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        searchByRange: 3,
        driverSearchType: "any",
        location: -1,
        status: -1,
        activeTrip: true,
        exception: false,
        company: 1,
        daysBack: 9,
      };
      setLocalSearchParams(defaultParams);
      localStorage.setItem("searchParameter", "");
      queryTrips(defaultParams);
    } else if (searchType === "jobIdList") {
      setJobIDList("");
      // Query with empty jobID list to get default results
      HttpRequest.jobIDListSearch(localStorage.getItem("access_token"), "")
        .then(async function (body) {
          const trips = body.data.map((trip, index) => ({
            ...trip,
            id: index,
            dtCallIn: Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm")
          }));
          setTrips(trips);
          setStartedSearch(true);
        });
    } else if (searchType === "referenceList") {
      setReferenceList("");
      setReferenceAccount("");
      // Query with empty reference list to get default results
      HttpRequest.referenceListSearch(localStorage.getItem("access_token"), { 
        referenceList: "",
        account: "" 
      }).then(async function (body) {
        const trips = body.data.map((trip, index) => ({
          ...trip,
          id: index,
          dtCallIn: Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm")
        }));
        setTrips(trips);
        setStartedSearch(true);
      });
    }
  };
  
  const handleStateReset = (event) => {    
    setStateReset(true);
    setSelection([]);
    clearSearch(); // Reuse the clearSearch logic
  };

  const renderSearchButton = (field) => {
    return (
        <FormControl className="fieldWrapper">
          <TextField
            variant="outlined"
            key={field[1]}
            InputProps={{ classes: { input: classes.Text } }}
            margin="dense"
            label={<Typography className={classes.Text}>{field[0]}</Typography>}
            value={localSearchParams[field[1]]}
            className={classes.textField}
            onChange={(searchedVal) =>
              setLocalSearchParams((prevState) => {
                return {
                  ...prevState,
                  [field[1]]: searchedVal.target.value,
                };
              })
            }
          />
        </FormControl>
    );
  };
/*{
        "buttonText": "RVT Sort",
        "searchID": 1
    },
    */
  const renderPreDefinedSearchButton = (field) => {
    console.log("renderPreDefinedSearchButton")
    return (
      <Box  m={2}>     
        <Button variant='contained' size='large'  color='primary' onClick={()=>{
            preDefinedSearch(field.searchID)
        }}>
          {field.buttonText}
        </Button>
      
      </Box>
    )
  };


  const renderDropDown = (title, key, items) => {
    return (
      <FormControl className="fieldWrapper">
        <TextField
          variant="outlined"
          key={key}
          label={<Typography className={classes.Text}>{title}</Typography>}
          className={classes.textField}
          value={localSearchParams[key]}
          margin="dense"
          select
          onChange={(e) =>
            setLocalSearchParams((prevState) => {
              return {
                ...prevState,
                [key]: e.target.value,
              };
            })
          }
        >
          {items.map((choice) => (
            <MenuItem key={choice.value} value={choice.value}>
              <Typography className={classes.Text}>{choice.label}</Typography>
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  };

  const preDefinedSearch = (searchID) => {
    //NoticeEmitter.emit("resetTable", "");   
    const params ={searchID: searchID}  
    const trips = [];
    setShowIndicator(true);
    HttpRequest.tripSearch(localStorage.getItem("access_token"), params).then(async function (body) {     
      await body.data.forEach((trip) => {
        trip.dtCallIn = Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm");
        trips.push(trip);
      });
      setTrips(trips);
      setStartedSearch(true);
      setShowIndicator(false);
    });
  };
    


  const queryTrips = (params) => {
    NoticeEmitter.emit("resetTable", "");
    const setNewSearchParams = (params) => {
      let temp = new Date(params.startDate);
      let start = new Date(temp.getFullYear(),temp.getMonth(), temp.getDate(), 0,0);
      start = new Date(Moment(start).unix() * 1000);
      temp = new Date(params.endDate);
      let end = new Date(temp.getFullYear(), temp.getMonth(), temp.getDate(), 23, 59);
      end = new Date(Moment(end).unix() * 1000);

      const storageStatusList = JSON.parse(localStorage.getItem("statusDDList"));
      const storageLocationList = JSON.parse(localStorage.getItem("locationDDList"));
      const loc = storageLocationList?storageLocationList.find(item => item.value ===  params.location):locationDDList?locationDDList.find(item => item.value ===  params.location):{value:-1, label:'ALL'};
      const stat = storageStatusList?storageStatusList.find(item => item.value ===  params.status):statusDDList?statusDDList.find(item => item.value ===  params.status):{value:-1, label:'ALL'};

      if (params.searchByRange === 2 || params.searchByRange === 3) {
        return {
          account: params.account,
          jobID: params.order,
          cust_ref: params.billing,
          caller: params.caller,
          addressID: params.addressID,
          ser_area: params.serviceArea,
          ser_time: params.serviceTime,
          companyName: params.shipperToFrom,
          drNum: params.drNum,
          contactName: params.name,
          contactPhone: params.phone,
          drSearchType: params.driverSearchType,
          companyID: params.company,
          exception: params.exception,
          activeTrip: params.activeTrip,
          exceptionFlag: params.exception.toString(),
          activeTripFlag: params.activeTrip.toString(),
          locationCode: params.location.toString(),
          statusCode: params.status.toString(),
          location: {
            locationCode: params.location,
            descr: loc.label,
            //descr: locationToString.get(params.location),
          },
          status: {
            statusCode: params.status,
            descr:stat.label,
            //descr: statusToString.get(params.status),
          },
          daysBack: params.searchByRange === 3 ? params.daysBack : null,
          startDate: start,
          endDate: end,
          //startDate: params.startDate.toString(),
          //endDate: params.endDate.toString(),
        };
      }
      if (params.searchByRange === 1) {
        return {
          account: params.account,
          jobID: params.order,
          cust_ref: params.billing,
          caller: params.caller,
          addressID: params.addressID,
          ser_area: params.serviceArea,
          ser_time: params.serviceTime,
          companyName: params.shipperToFrom,
          drNum: params.drNum,
          contactName: params.name,
          contactPhone: params.phone,
          drSearchType: params.driverSearchType,
          companyID: params.company,
          exception: params.exception,
          activeTrip: params.activeTrip,
          exceptionFlag: params.exception.toString(),
          activeTripFlag: params.activeTrip.toString(),
          locationCode: params.location.toString(),
          statusCode: params.status.toString(),
          daysBack: null,
          location: {
            locationCode: params.location,
            descr: loc.label,
            //descr: locationToString.get(params.location),
          },
          status: {
            statusCode: params.status,
            descr:stat.label,
            //descr: statusToString.get(params.status),
          },
          monthDate: params.date.toUTCString(),
        };
      }
    };
    console.log("tripSearch -" + JSON.stringify(setNewSearchParams(params)));
    const trips = [];
    setShowIndicator(true);
    HttpRequest.tripSearch(localStorage.getItem("access_token"), setNewSearchParams(params)).then(async function (body) {
      localStorage.setItem("searchParameter", JSON.stringify(params));
      await body.data.forEach((trip) => {
        trip.dtCallIn = Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm");
        trips.push(trip);
      });
      setTrips(trips);
      setStartedSearch(true);
      setShowIndicator(false);
    });
  };
    

  const handleSearchTypeChange = (event) => {    
    console.log("handleSearchTypeChange-" + event.target.value);
    const newSearchType = event.target.value;
    setSearchType(newSearchType);
    // Clear previous results
    setTrips([]);
    setStartedSearch(false);

    // Handle switching to different search types
    switch(newSearchType) {
        case "standard":
            queryTrips(localSearchParams);
            break;
        case "jobIdList":
            if(jobIDList.length > 0) {
                jobIDListSearch();
            }
            break;
        case "referenceList":
            if(referenceList.length > 0) {
                referenceListSearch();
            }
            break;
        case "pre-defined":
            // Don't auto-search for pre-defined
            break;
    }
};

  const renderIconButton =(title, onClickHandler, disabled, label, className, Icon) =>{
    return(
      <Tooltip title={title}>
        <IconButton onClick={onClickHandler} disabled={disabled} aria-label={label} className={className}><Icon /></IconButton>
      </Tooltip>
    )
  }

  return (
    <div className='container_search-list'>

      <div id="searchAppBar">
      <FormControl className="search-toggle_form">
            <Typography className="search_heading">Search Type</Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="standard"
              name="radio-buttons-group"
              onChange={handleSearchTypeChange}
              className="radio_group"
            >
              <FormControlLabel value="standard" control={<Radio size="small" />} label={<Typography className="search_label">Standard</Typography>} />
              <FormControlLabel value="jobIdList" control={<Radio size="small" />} label={<Typography className="search_label">JobID List</Typography>} />
              <FormControlLabel value="referenceList" control={<Radio size="small" />} label={<Typography className="search_label">Reference List</Typography>} />
              <FormControlLabel value="pre-defined" control={<Radio size="small" />} label={<Typography className="search_label">Pre-defined</Typography>} />
            </RadioGroup>
          </FormControl>
          <div className="accordion_buttons">
            {renderIconButton("State Reset", handleStateReset, false, "StateReset","iconButton-red",RefreshIcon)}
            {renderIconButton("Map", showMap, selection.length === 0, "Map", "iconButton-purple", MapIcon)}
            {renderIconButton("Save", saveSearchQuery, false, "Save", "iconButton-green", SaveIcon)}
            {renderIconButton("Clear", clearSearch, false, "Clear", "iconButton-red", ClearIcon)}
            {renderIconButton("Search", handleSearch, false, "Search", "iconButton-orange", SearchIcon)}
            {/* <Tooltip title="Map">
              <IconButton onClick={showMap} disabled={selection.length == 0} aria-label="Map" className="iconButton-purple"><MapIcon /></IconButton>
              </Tooltip>
            <Tooltip title="Save">
              <IconButton onClick={saveSearchQuery} aria-label="Save" className="iconButton-green"><SaveIcon /></IconButton>
            </Tooltip>
              <Tooltip title="Clear">
            <IconButton onClick={clearSearch} aria-label="Clear" className="iconButton-red"><ClearIcon /></IconButton>
            </Tooltip>
            <Tooltip title="Search">
              <IconButton onClick={handleSearch} aria-label="Search" className="iconButton-orange"><SearchIcon /></IconButton>
            </Tooltip>
            <Tooltip title="State Reset">
              <IconButton onClick={handleStateReset} aria-label="StateReset" className="iconButton-orange"><RefreshIcon /></IconButton>
            </Tooltip> */}
          </div>
      </div>

      {/* <div className={isScrollValueMoreThanHeaderHeight ? "position-fixed" : "position-static"}> */}
      <Accordion defaultExpanded='true'>
        <AccordionSummary
          // sx={{ pointerEvents: "none" }}
          expandIcon={<ExpandMoreIcon className="colorPrimary" />}
          //aria-controls="panel1a-content"
          // id="panel1a-header"
        >
          
        </AccordionSummary>
        <AccordionDetails>
          {searchType === 'jobIdList' && (
            <Grid container direction="row" className="darkForm" justifyContent="start" alignItems="flex-start"
              style={{ margin: "0", width: "100%" }} spacing={0}>
              <FormControl className="fieldWrapper">
                <TextField 
                  id="jobID" 
                  type="number" 
                  variant="outlined" 
                  InputProps={{ classes: { input: classes.Text } }} 
                  margin="dense"
                  label={<Typography className={classes.Text}>Add jobID</Typography>}
                  className={classes.textField}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      setJobIDList(jobIDList + e.target.value + ',')
                      document.getElementById('jobID').value = "";
                    }
                  }}
                />
              </FormControl>
              <FormControl className="textareaWrapper">
                <TextareaAutosize 
                  minRows={5} 
                  placeholder="jobID list"
                  value={jobIDList} 
                  onChange={e => { setJobIDList(e.target.value) }}
                />
              </FormControl>
            </Grid>
          )}

          {searchType === 'referenceList' && (
            <Grid container direction="row" className="darkForm" justifyContent="start" alignItems="flex-start"
              style={{ margin: "0", width: "100%" }} spacing={0}>
              <FormControl className="fieldWrapper">
                <TextField 
                  id="reference"
                  variant="outlined"
                  InputProps={{ classes: { input: classes.Text } }}
                  margin="dense"
                  label={<Typography className={classes.Text}>Add Reference</Typography>}
                  className={classes.textField}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      setReferenceList(referenceList + e.target.value + ',')
                      document.getElementById('reference').value = "";
                    }
                  }}
                />
              </FormControl>
              <FormControl className="fieldWrapper">
                <TextField 
                  id="referenceAccount"
                  variant="outlined"
                  InputProps={{ classes: { input: classes.Text } }}
                  margin="dense"
                  label={<Typography className={classes.Text}>Account (Optional)</Typography>}
                  className={classes.textField}
                  onChange={(e) => setReferenceAccount(e.target.value)}
                />
              </FormControl>
              <FormControl className="textareaWrapper">
                <TextareaAutosize 
                  minRows={5}
                  placeholder="reference list"
                  value={referenceList}
                  onChange={e => { setReferenceList(e.target.value) }}
                />
              </FormControl>
            </Grid>
          )}
              {searchType==='pre-defined'  && <Box display="flex" flexWrap="nowrap" flexDirection="column" m={0}>
                <Box display="flex" justifyContent="stretch" space={2} border={0} className={"darkForm"} sx={{ height: "auto" }}>
                  {preDefinedOptions.map((field) => renderPreDefinedSearchButton(field))}
                </Box>
              </Box>}    
              {searchType==='standard'  && <Box display="flex" flexWrap="nowrap" flexDirection="column" m={0}>
                <Box display="flex" justifyContent="stretch" border={0} className={"darkForm"} sx={{ height: "auto" }}>
                  {row1.map((field) => renderSearchButton(field))}
                  {/* {dropDowns.map((field) => renderDropDowns(field))} */}
                  {renderDropDown("Driver Search Type","driverSearchType",driverSearchType)}
                  {locationDDList!==null && renderDropDown("Location","location",locationDDList)}
                  {statusDDList!==null && renderDropDown("Status","status",statusDDList)}
                  {row2.map((field) => renderSearchButton(field))}
                  <div className="formRowBottom">
                    <TextField style={{ width: 150 }} variant="outlined" label={<Typography className={classes.Text}>Search Type</Typography> }
                      className={classes.textField}
                      margin="dense" select
                      value={localSearchParams.searchByRange}
                      onChange={(searchedVal) =>
                        setLocalSearchParams((prevState) => {
                          return {
                            ...prevState,
                            searchByRange: searchedVal.target.value,
                          };
                        })
                      }
                    >
                      <MenuItem value={1}>
                        <Typography className={classes.Text}>Month</Typography>
                      </MenuItem>
                      <MenuItem value={2}>
                        <Typography className={classes.Text}>Range</Typography>
                      </MenuItem>
                      <MenuItem value={3}>
                        <Typography className={classes.Text}>Days Back</Typography>
                      </MenuItem>
                    </TextField>
                    <TextField
                      variant="outlined"  margin="dense" label="Days Back"  type="number"
                      InputProps={{ classes: { input: classes.Text } }}
                      value={localSearchParams["daysBack"]}
                      className={localSearchParams.searchByRange === 3 ? classes.textField : classes.invisible}
                      onChange={(searchedVal) => {
                        setLocalSearchParams((prevState) => {
                          return {
                            ...prevState,
                            daysBack: searchedVal.target.value,
                            startDate: new Date(new Date().getTime() - searchedVal.target.value * 24 * 60 * 60 * 1000),
                            endDate: new Date(),
                          };
                        });
                      }}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"  variant="inline" size="small"  margin="dense"
                        label={<Typography className={classes.Text}>Start Date</Typography>}
                        value={localSearchParams.startDate}
                        // onChange={this.handleStartDateChange}
                        onError={console.log}
                        format="yyyy-MM-dd"                     
                        style={{ width: 180 }}
                        className={localSearchParams.searchByRange === 2 ? classes.textField : classes.invisible}
                        KeyboardButtonProps={{"aria-label": "change date",}}
                        onChange={(searchedVal) =>
                          setLocalSearchParams((prevState) => {
                            return {
                              ...prevState,
                              startDate: searchedVal,
                            };
                          })
                        }
                      />
                      <KeyboardDatePicker
                        inputVariant="outlined"  variant="inline"  size="small"  margin="dense"
                        label={<Typography className={classes.Text}>End Date</Typography>}
                        value={localSearchParams.endDate}                     
                        onError={console.log}
                        format="yyyy-MM-dd"
                        // style={styles.dateTimePicker}
                        style={{ width: 180 }}
                        className={localSearchParams.searchByRange === 2 ? classes.textField: classes.invisible}
                        KeyboardButtonProps={{"aria-label": "change date"}}
                        onChange={(searchedVal) =>
                          setLocalSearchParams((prevState) => {
                            return {
                              ...prevState,
                              endDate: searchedVal,
                            };
                          })
                        }
                      />
                      <KeyboardDatePicker
                        inputVariant="outlined" variant="inline" openTo="month"  views={["year", "month"]}  style={{ width: 150 }} format="MM/yyyy"  margin="dense"
                        value={localSearchParams.date}
                        label={<Typography className={classes.Text}>Month</Typography>}
                        className={localSearchParams.searchByRange === 1? classes.textField : classes.invisible}
                        onChange={(searchedVal) =>
                          setLocalSearchParams((prevState) => {
                            return {
                              ...prevState,
                              date: searchedVal,
                            };
                          })
                        }
                      />
                    </MuiPickersUtilsProvider>
                    <div className="wrapperCheckboxes">
                      <FormGroup row>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox label="Active Trip"
                                checked={localSearchParams.activeTrip}
                                color="primary"
                                onChange={(searchedVal) => {                         
                                  let searchParams = {
                                    ...localSearchParams,
                                    daysBack: searchedVal.target.checked? 9 : localSearchParams.daysBack,
                                    activeTrip: searchedVal.target.checked,
                                  };                                  
                                  setLocalSearchParams(searchParams);
                                  queryTrips(searchParams);
                                }}
                              />
                            }
                            label={<Typography>Active</Typography>}
                          />
                        </FormControl>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox  color="primary"  label="Exception"
                                checked={localSearchParams.exception}
                                onChange={(searchedVal) => {                        
                                  let searchParams = {
                                    ...localSearchParams,
                                    exception: searchedVal.target.checked,
                                  };
                                  setLocalSearchParams(searchParams);
                                  queryTrips(searchParams);
                                }}
                              />
                            }
                            label={<Typography>Exception</Typography>}
                          />
                        </FormControl>
                      </FormGroup>
                    </div>
                    <div className="wrapperCheckboxes">
                      <FormGroup row>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox label="Default" checked={defaultChecked} color="primary"
                              onChange={(event) => {
                                setDefaultChecked(event.target.checked);
                                setVerificationChecked(false);
                                setDispatchChecked(false);                         
                                let searchParams = {
                                  ...localSearchParams,                           
                                  searchByRange: 3,
                                  daysBack: 9,
                                  activeTrip: true,
                                  status: -1,
                                };
                                setLocalSearchParams(searchParams);
                                queryTrips(searchParams);
                              }}
                            />
                          }
                            label={<Typography> {" "} Default </Typography>}
                          />
                        </FormControl>               
                        <FormControl>
                          <FormControlLabel
                            control={
                            <Checkbox color="primary" label="Verification"
                              checked={verficationChecked}
                              onChange={(event) => {
                                setVerificationChecked(event.target.checked);
                                setDispatchChecked(false);
                                setDefaultChecked(false);                         
                                let searchParams = {
                                  ...localSearchParams,
                                  searchByRange: 3,
                                  daysBack: 9,
                                  activeTrip: false,
                                  status: 8,
                                };
                                setLocalSearchParams(searchParams);
                                queryTrips(searchParams);
                              }}
                            />
                            }
                            label={<Typography>Verification</Typography>}
                          />
                        </FormControl>
                      </FormGroup>
                    </div>
                  </div>           
                </Box>
              </Box>}
        </AccordionDetails>
      </Accordion>
      {/* </div> */}

      {showIndicator && (
        <Stack justifyContent="center"  alignItems="center"  spacing={2} direction="row">
          <CircularProgress />
        </Stack>
      )}
      {startedSearch && trips.length === 0 && (
        <div style={{width: "100%", display: "inline_block", backgroundColor: "#fff3cd", textAlign: "center" }}>
          <Typography variant="h6" style={{ color: "#a9a9a9" }}>
            {"No results found"}
          </Typography>
        </div>
      )}
      <div style={{ display: "block", position: "relative", flex: "1 1 auto", minHeight: "400px" }}>
        <div id="leftPanel">
          {trips.length > 0 && (<SideButtons accessToken={props.accessToken}  buttonList={buttons}  selection={selection}/>)}
        </div>
        <div id="mainPanel">
          {trips.length > 0 && (
            <ToggleDataGrid 
              trips={trips}
              selection={selection}
              setSelection={setSelection}
              defaultChecked={defaultChecked}
              dispatchChecked={dispatchChecked}
              verficationChecked={verficationChecked}
              stateReset={stateReset}
              setStateReset={setStateReset}
            />
          )}
        </div>
      </div>
      <footer>
        <Copyright />
      </footer>
    </div>    
  );
}
