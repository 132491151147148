import React from 'react';
import { Button } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@material-ui/core";

const DashboardMessageDialog = props => {   
  const handleMsgInputChange = (event) => {
    console.log("handleMsgInputChange", event.target.value);
    props.setMsgToDriver(event.target.value);
  };
  
  const handleClose = () => {
    props.setMsgBoxOpen(false);
  };
  
  return (
    <Dialog
      className="darkDialog"
      open={props.msgBoxOpen}
      onClose={handleClose} // Changed from onClick to onClose
    >
      <DialogTitle>Send Message</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus={true}
          variant="outlined"
          margin="dense"
          label="Message"
          type="text"
          fullWidth
          onChange={handleMsgInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.messageSubmit}>Submit</Button>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DashboardMessageDialog;