import React, { useEffect, useState }from 'react'
import { useHistory  } from 'react-router-dom';
import httpRequest from '../../HttpService';
import Map, { Marker, FullscreenControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ExceptionPin from './ExceptionPin';
import DriverPin from './DriverPin';
import TripPin from './TripPin';
import BreadcrumbPin from './BreadcrumbPin';


/*
TODO: 
1. Hover over driven icon displays driver contact info at top
2. Double click driver icon displays driver messages
3. if router's url contains driver id, then display only the driver
*/


const OneDriversMapPanel = props => {
    const history = useHistory();  
    const [mapPoints, setMapPoints] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(props.currentDriver);
    const [exceptionMapPoints, setExceptionMapPoints] = useState([]);
    const [driverMapPoints, setDriverMapPoints] = useState([]);
    const [tripMapPoints, setTripMapPoints] = useState([]);

    const [pickTripMapPoints, setPickTripMapPoints] = useState([]);
    const [deliveryTripMapPoints, setDeliveryTripMapPoints] = useState([]);

    const [breadCrumbPoints, setBreadCrumbPoints] = useState([]);
    const [breadCrumbPoints_location, setBreadCrumbPoints_location] = useState([]);
    const [breadCrumbPoints_order, setBreadCrumbPoints_order] = useState([]);

    const [showRelatedTripPIN, setShowRelatedTripPIN] = useState(false);
    const [relatedTripPIN, setRelatedTripPIN] = useState(null);
    const [relatedOrgTripPIN, setRelatedOrgTripPIN] = useState(null);
    const [showOrgRelatedPIN, setShowOrgRelatedTripPIN] = useState(false);
    const [relatedTripPINColor, setRelatedTripPINColor] = useState('');

    

    useEffect(() => {

        //console.log(">>>>>>>>>>>>>>>>>>OneDriversMapPanel  currentDriver" + props.currentDriver);
        //console.log(">>>>>>>>>>>>>>>>>>OneDriversMapPanel  tripList" + props.tripList.length);

        const drvPointsList=[];
        const tripPointsList=[];
        const exceptionPointsList=[];

        const deliveryTripPointsList=[];
        const pickTripPointsList=[];

        httpRequest.getMapPointsAll(props.accessToken, props.companyID).then(async function(body){
            await body.data.forEach((value) => {
                if(value.mapImage==="Car" && value.drNum === props.currentDriver){
                    if(value.exception!== null && value.exception === true )
                        exceptionPointsList.push(value);
                    else
                        drvPointsList.push(value);
                }
                else if(value.drNum === props.currentDriver){
                    tripPointsList.push(value); 
                    if(value.mapLabel.toUpperCase() === "D" || value.mapLabel.toUpperCase() === "DD" || value.mapLabel.toUpperCase() === "SD" || value.mapLabel.toUpperCase() === "RD" || value.mapLabel.toUpperCase() === "DN")
                        deliveryTripPointsList.push(value);
                    else
                        pickTripPointsList.push(value);                      
                }
             });              

            setDeliveryTripMapPoints(deliveryTripPointsList);
            setPickTripMapPoints(pickTripPointsList);

            setDriverMapPoints(drvPointsList);
            setTripMapPoints(tripPointsList);
            setExceptionMapPoints(exceptionPointsList);
            setMapPoints(body.data);

        });
    },[props.currentDriver]);
    //},[props.currentDriver,props.tripPinsList]);


    useEffect(() => {          
        console.log("OneDriversMap - getBreadCrumbPoints - startTime-" + props.startTime);
        console.log("OneDriversMap - getBreadCrumbPoints - endTime-" + props.endTime);
        let locationList = [];
        let orderList = [];
        if(String(props.startTime) === String(props.endTime)) {
            httpRequest.getBreadcrumbPoints(props.accessToken,  props.currentDriver).then(async function(body){  
                //console.log("OneDriversMap - getBreadCrumbPoints-" + JSON.stringify(body.data));
                console.log("OneDriversMap === getBreadCrumbPoints-" + body.data.length);
                body.data.forEach((value) => {
                    if(value.type.toUpperCase().includes("LOCATION"))
                       locationList.push(value);
                    else
                       orderList.push(value);
                });
                setBreadCrumbPoints_location(locationList);
                setBreadCrumbPoints_order(orderList);
                setBreadCrumbPoints(body.data);
            });
        }
        else{
            httpRequest.getBreadcrumbPointsWithRange(props.accessToken,  props.currentDriver, props.startTime, props.endTime).then(async function(body){  
                //console.log("OneDriversMap - getBreadCrumbPoints-" + JSON.stringify(body.data));
                console.log("OneDriversMap - getBreadCrumbPoints-" + body.data.length);
                body.data.forEach((value) => {
                    if(value.type.toUpperCase().includes("LOCATION"))
                       locationList.push(value);
                    else
                       orderList.push(value);
                });
                setBreadCrumbPoints_location(locationList);
                setBreadCrumbPoints_order(orderList);
                setBreadCrumbPoints(body.data);
            });            
        }

    },[props.currentDriver,props.IsShowBreadcrumbPoints, props.startTime, props.endTime]);



    function handleIconClick(drNum, json){
        setSelectedDriver(drNum);
        json = JSON.parse(json);
        var msg = "";
        if(drNum !== null){
            msg = "Driver #: " + drNum + " | Phone: " + json[0].ContactPhone
            + " | Email: " + json[0].contactEmail + " | GeoMins: " + json[0].GeoMinutes
            + " | Refused: " + json[0].refused + " | Burns: " + json[0].burns
            + " | Trip Count: " + json[0].tripCount + " | Trips: " + json[0].trips;
        }
        console.log(msg);
    }

    function handleTripClick(drNum, jobID, json){
        console.log("handleTripClick" + jobID);
        let inxURL = "/jobdetail/"+jobID;
        window.open(inxURL, "_blank");
    }

    function handleMouseOverBreadCrumb(pin){
        console.log("handleMouseOverBreadCrumb" + JSON.stringify(pin));
    }


    function handleMouseOver(pin){
        console.log("handleMouseOver" + JSON.stringify(pin));
        if(relatedTripPIN !== null) return;
        var target;
        switch(pin.mapLabel.toUpperCase()) {
                case "D":
                    target = "P";
                    break;
                case "DD":
                    target = "PP";
                    break;
                case "P":
                    target = "D";
                    break;
                case "PP":
                default:
                    target = "DD";
                    break;
        }
        mapPoints.map(item => {
            if(item.jobID===pin.jobID && item.mapLabel.toUpperCase() === target){

               setRelatedTripPINColor(item.mapColor);
               setRelatedOrgTripPIN(item);

               item.mapColor = 'light green';
               setRelatedTripPIN(item);
               localStorage.setItem('relatedPIN', JSON.stringify(item));

               setShowRelatedTripPIN(true);
               setShowOrgRelatedTripPIN(false);

               //setTimeout(() => {  console.log("World!"); }, 2000);
               let cnt =0;
               let timerId = setInterval(() => {                  
                    cnt++;
                    if(cnt >=1 ){                          
                        clearInterval(timerId);
                        setRelatedTripPIN(null);
                        setRelatedOrgTripPIN(null);
                        setShowOrgRelatedTripPIN(false);
                        setShowRelatedTripPIN(false);
                        //NoticeEmitter.emit('freshAllDriverMap', ''); 
                    }                  
                }, 1500);                
            }
        });
    }
    
    function renderRelatedTripPin(){
        return(
                relatedTripPIN? <Marker 
                    longitude={relatedTripPIN.long}
                    latitude={relatedTripPIN.lat}
                 >
                    <TripPin onClick={() => handleTripClick(relatedTripPIN.drNum,relatedTripPIN.jobID,relatedTripPIN.json)} pin={relatedTripPIN} 
                         json={relatedTripPIN.json} onMouseOver={() => {}}/>
                </Marker> :<></> 
                
    )}

   function renderRelatedOrgTripPin(){
        return(
                <Marker 
                    longitude={relatedOrgTripPIN.long}
                    latitude={relatedOrgTripPIN.lat}
                >
                    <TripPin onClick={() => handleTripClick(relatedOrgTripPIN.drNum,relatedOrgTripPIN.jobID,relatedOrgTripPIN.json)} pin={relatedOrgTripPIN} 
                        json={relatedOrgTripPIN.json} onMouseOver={() => handleMouseOver(relatedOrgTripPIN)}/>
                </Marker>   
                    
    )}   
    
    return (mapPoints[mapPoints.length-1] !== undefined) ? (
        <Map
            initialViewState={{
                longitude: mapPoints[0].long,
                latitude: mapPoints[0].lat,
                pitch:0,
                zoom: 10
            }}
            style={{width: "100%", height: "80vh"}}
            mapStyle="mapbox://styles/mapbox/streets-v9"
             mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        >
            {driverMapPoints.map((value) => 
                 <Marker 
                    longitude={value.long}
                    latitude={value.lat}
                 >
                    <DriverPin onHover={() => props.hoverHandler(value.drNum, value.json)} onClick={() => handleIconClick(value.drNum, value.json)} drNum={value.drNum} pin={value}/>
                </Marker>            
            )}  
     
            {exceptionMapPoints.map((value) => 
                 <Marker 
                    longitude={value.long}
                    latitude={value.lat}
                 >
                    <DriverPin onHover={() => props.hoverHandler(value.drNum, value.json)} onClick={() => handleIconClick(value.drNum, value.json)} drNum={value.drNum} pin={value}/>
                    <ExceptionPin/>
                </Marker>            
            )}  

            {/* {tripMapPoints.map((value) => 
                 <Marker 
                    longitude={value.long}
                    latitude={value.lat}
                 >
                    <TripPin onClick={() => handleTripClick(value.drNum,value.jobID,value.json)} pin={value} 
                           json={value.json} onMouseOver={() => handleMouseOver(value)}/>
                </Marker>            
            )}   */}

            {props.IsShowPickup && pickTripMapPoints.map((value) => 
                 <Marker 
                    longitude={value.long}
                    latitude={value.lat}
                 >
                    <TripPin onClick={() => handleTripClick(value.drNum,value.jobID,value.json)} pin={value} 
                    json={value.json} onMouseOver={() => handleMouseOver(value)}/>
                </Marker>            
            )} 

            {props.IsShowDelivery && deliveryTripMapPoints.map((value) => 
                 <Marker 
                    longitude={value.long}
                    latitude={value.lat}
                 >
                     <TripPin onClick={() => handleTripClick(value.drNum,value.jobID,value.json)} pin={value} 
                    json={value.json} onMouseOver={() => handleMouseOver(value)}/>
                </Marker>            
            )}             


            {showRelatedTripPIN?
                renderRelatedTripPin():relatedOrgTripPIN!== null ? renderRelatedOrgTripPin():<></>
            } 


            {props.IsShowBreadcrumbPoints && breadCrumbPoints_location.map((value) => 
                 <Marker 
                    longitude={value.long}
                    latitude={value.lat}
                 >
                    <BreadcrumbPin onHover={() => handleMouseOverBreadCrumb(value)} pin={value}/>
                </Marker>            
            )}   

            {props.IsShowBreadcrumbPoints && breadCrumbPoints_order.map((value) => 
                 <Marker 
                    longitude={value.long}
                    latitude={value.lat}
                 >
                    <BreadcrumbPin onHover={() => handleMouseOverBreadCrumb(value)} pin={value}/>
                </Marker>            
            )}   
 
            
            <FullscreenControl/>
        </Map>
    ) : <></>;

};
export default OneDriversMapPanel;
