import React, { useState, useRef } from 'react';
import { 
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useHistory } from 'react-router-dom';
import httpRequest from '../HttpService';

const baseStorageUrl = 'https://directitsqlblob.blob.core.windows.net/order-files/';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: '#0E1419',
    minHeight: '100vh',
    color: 'white'
  },
  title: {
    color: 'white',
    marginBottom: theme.spacing(3),
    fontSize: '2rem'
  },
  form: {
    maxWidth: 600
  },
  uploadArea: {
    width: '100%',
    height: 200,
    border: '2px dashed rgba(255, 255, 255, 0.2)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(3),
    position: 'relative',
    transition: 'all 0.2s ease',
    '&:hover': {
      borderColor: '#1FB6FF',
      '& $uploadIcon': {
        color: '#1FB6FF'
      }
    },
    '&.dragOver': {
      borderColor: '#1FB6FF',
      backgroundColor: 'rgba(31, 182, 255, 0.1)'
    }
  },
  uploadIcon: {
    fontSize: 48,
    color: 'rgba(255, 255, 255, 0.7)',
    marginBottom: theme.spacing(2),
    transition: 'color 0.2s ease'
  },
  uploadText: {
    color: 'rgba(255, 255, 255, 0.7)',
    marginBottom: theme.spacing(1)
  },
  uploadSubText: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '0.875rem'
  },
  input: {
    display: 'none'
  },
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.2)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.3)',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1FB6FF',
      }
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#1FB6FF',
    }
  },
  checkbox: {
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-checked': {
      color: '#1FB6FF'
    }
  },
  checkboxLabel: {
    color: 'rgba(255, 255, 255, 0.7)',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  uploadButton: {
    backgroundColor: '#1FB6FF',
    color: 'white',
    padding: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: '#1994cc'
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(31, 182, 255, 0.3)',
      color: 'rgba(255, 255, 255, 0.3)'
    }
  },
  selectedFile: {
    color: '#1FB6FF',
    fontWeight: 500
  },
  errorText: {
    color: '#ff4d4d',
    marginTop: theme.spacing(1)
  },
  successText: {
    color: '#00cc66',
    marginTop: theme.spacing(1)
  },
  urlText: {
    color: 'rgba(255, 255, 255, 0.7)',
    marginTop: theme.spacing(2),
    wordBreak: 'break-all'
  },
  urlLink: {
    color: '#1FB6FF',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const FileUploadForm = () => {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [fileNote, setFileNote] = useState('');
  const [stagingCode, setStagingCode] = useState('');
  const [isLegacy, setIsLegacy] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [blobFileName, setBlobFileName] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef(null);
  const uploadAreaRef = useRef(null);
  const history = useHistory();

  // Prevent default drag behavior anywhere on the form
  const handleDocumentDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Set up document-level drag event handlers
  React.useEffect(() => {
    document.addEventListener('dragover', handleDocumentDragOver);
    document.addEventListener('drop', handleDocumentDragOver);

    return () => {
      document.removeEventListener('dragover', handleDocumentDragOver);
      document.removeEventListener('drop', handleDocumentDragOver);
    };
  }, []);
  
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    processFile(selectedFile);
  };

  const processFile = (selectedFile) => {
    console.log('Processing file:', selectedFile);
    
    if (selectedFile) {
      if (selectedFile.size > 10 * 1024 * 1024) {
        setError('File size must be less than 10MB');
        setSuccess('');
        setFile(null);
        return;
      }
      
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['csv', 'xls', 'xlsx'];
      
      if (!allowedExtensions.includes(fileExtension)) {
        setError('Please upload only CSV or Excel files');
        setSuccess('');
        setFile(null);
        return;
      }
      
      console.log('File accepted:', selectedFile.name);
      setError('');
      setSuccess('');
      setBlobFileName(null);
      setFile(selectedFile);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget === uploadAreaRef.current) {
      setIsDragOver(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget === uploadAreaRef.current) {
      setIsDragOver(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget === uploadAreaRef.current) {
      // Only set dragOver to false if we're leaving the upload area itself
      // and not entering a child element
      if (!e.currentTarget.contains(e.relatedTarget)) {
        setIsDragOver(false);
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      processFile(droppedFiles[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!file) {
      setError('Please select a file to upload');
      return;
    }

    if (!fileNote.trim()) {
      setError('Please enter a file note');
      return;
    }

    if (!stagingCode.trim()) {
      setError('Please enter a staging code');
      return;
    }

    setIsUploading(true);
    setError('');
    setSuccess('');
    setBlobFileName(null);

    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        history.push('/');
        return;
      }

      const reader = new FileReader();
      reader.readAsBinaryString(file);
      
      reader.onload = async (event) => {
        try {
          const base64String = btoa(event.target.result);
          console.log('Converting file to base64');
          
          const fileName = `${Date.now()}-${file.name}`;
          console.log('Uploading file to blob storage');
          
          const uploadResponse = await httpRequest.doUploadFile(
            accessToken,
            base64String,
            fileName
          );

          console.log('Upload response:', uploadResponse);

          if (uploadResponse.status !== 200) {
            throw new Error('Upload to blob storage failed - server error');
          }

          const importContent = {
            fileURL: fileName,
            fileNote: fileNote,
            stagingCode: stagingCode,
            isLegacy: isLegacy
          };

          console.log('Creating import record with content:', importContent);

          const importResponse = await httpRequest.importFile(accessToken, importContent);
          console.log('Import response:', importResponse);

          if (importResponse.status !== 200) {
            throw new Error('Import record creation failed - server error');
          }

          setFile(null);
          setFileNote('');
          setStagingCode('');
          setIsLegacy(false);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          
          setSuccess('File uploaded and imported successfully');
          setBlobFileName(fileName);
          
        } catch (err) {
          console.error('Upload/Import failed:', err);
          setError(err.message || 'Failed to upload file. Please try again.');
        } finally {
          setIsUploading(false);
        }
      };

      reader.onerror = () => {
        console.error('Error reading file');
        setError('Error reading file. Please try again.');
        setIsUploading(false);
      };

    } catch (err) {
      console.error('Upload preparation failed:', err);
      setError('Failed to prepare file upload. Please try again.');
      setIsUploading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        File Upload
      </Typography>
      
      <form 
        onSubmit={handleSubmit} 
        className={classes.form}
        onDragOver={handleDocumentDragOver}
        onDrop={handleDocumentDragOver}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".csv,.xlsx,.xls"
          className={classes.input}
          id="file-upload"
        />
        
        <label 
          htmlFor="file-upload" 
          className={`${classes.uploadArea} ${isDragOver ? 'dragOver' : ''}`}
          ref={uploadAreaRef}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <CloudUploadIcon className={classes.uploadIcon} />
          {file ? (
            <>
              <Typography variant="body1" className={classes.selectedFile}>
                {file.name}
              </Typography>
              <Typography variant="caption" className={classes.uploadSubText}>
                Click to change file
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body1" className={classes.uploadText}>
                Drop file here or click to browse
              </Typography>
              <Typography variant="caption" className={classes.uploadSubText}>
                (CSV or Excel files only, max 10MB)
              </Typography>
            </>
          )}
        </label>

        <TextField
          fullWidth
          label="File Note"
          value={fileNote}
          onChange={(e) => setFileNote(e.target.value)}
          variant="outlined"
          className={classes.textField}
        />

        <TextField
          fullWidth
          label="Staging Code"
          value={stagingCode}
          onChange={(e) => setStagingCode(e.target.value)}
          variant="outlined"
          className={classes.textField}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={isLegacy}
              onChange={(e) => setIsLegacy(e.target.checked)}
              className={classes.checkbox}
            />
          }
          label="Legacy"
          className={classes.checkboxLabel}
        />

        {error && (
          <Typography className={classes.errorText}>
            {error}
          </Typography>
        )}

        {success && (
          <Typography className={classes.successText}>
            {success}
          </Typography>
        )}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isUploading}
          className={classes.uploadButton}
          startIcon={<CloudUploadIcon />}
        >
          {isUploading ? 'Uploading...' : 'Upload File'}
        </Button>

        {blobFileName && (
          <Box mt={2}>
            <Typography className={classes.urlText}>
              File URL:{' '}
              <a 
                href={`${baseStorageUrl}${blobFileName}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.urlLink}
              >
                {baseStorageUrl}{blobFileName}
              </a>
            </Typography>
          </Box>
        )}
      </form>
    </div>
  );
};

export default FileUploadForm;