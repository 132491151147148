import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";

import AttachmentIcon from "@mui/icons-material/Attachment";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HeadphonesIcon from "@mui/icons-material/Headphones";

import httpRequest from "../../HttpService";
import Moment from "moment";

/*
-On page load/Show All button click, All messages are to be shown (getMessagesAll)
-When a specific driver number is inputted, ONLY the service messages and the driver's messages 
    will be shown (getServiceMessages, getMessagesFromDriver)
-Sending messages to a driver (sendDriverMessage)
*/

//import { DataGrid } from '@mui/x-data-grid';
import { DataGrid } from "@material-ui/data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";

const MessageList = (props) => {
  const [allMessages, setAllMessages] = useState([]);
  const [msg, setMsg] = useState("");
  const [curDriver, setCurDriver] = useState("");

  useEffect(() => {
    getMessage();
    const interval = setInterval(() => {
      getMessage();
    }, 60000);
    return () => clearInterval(interval);
  }, [props.companyID]);

  useEffect(() => {
    const messageList = [];
    httpRequest
      .getServiceMessages(props.accessToken)
      .then(async function (body) {
        await body.data.forEach((value) => {
          value.datestampUTC = Moment(value.datestampUTC).format(
            "YY-MM-DD HH:mm"
          );
          value.msgLine = value.messageText;
          messageList.push(value);
        });
      });
    //console.log(messageList);
  }, []);

  const getMessage = () => {
    const allMessageList = [];
    var current = new Date();
    var startTime = new Date();
    if (current.getHours() < 2)
      startTime = new Date(
        current.getFullYear(),
        current.getMonth(),
        current.getDate(),
        0,
        0
      );
    else
      startTime = new Date(
        current.getFullYear(),
        current.getMonth(),
        current.getDate(),
        current.getHours() - 4,
        0
      );
    httpRequest
      .getMessagesAll(props.accessToken, props.companyID, startTime, 10000)
      .then(async function (body) {
        await body.data.forEach((value) => {
          //console.log("value is " + JSON.stringify(value));
          value.datestampUTC = Moment(value.datestampUTC).format(
            "YY-MM-DD HH:mm"
          );
          allMessageList.push(value);
        });
        allMessageList.sort((a, b) =>
          a.datestampUTC > b.datestampUTC ? -1 : 1
        );

        const listWithID = [];
        var currentID = 0;
        allMessageList.map((item) => {
          listWithID.push({
            ...item,
            id: currentID,
          });
          currentID = currentID + 1;
        });
        setAllMessages(listWithID);
      });
  };

  const renderJobID = (params) => {
    return <a className="jobIDLink">{params.value} </a>;
  };

  const renderDrNum = (params) => {
    return <a className="priorityMedium">{params.value} </a>;
  };

  const renderMedia = (params) => {
    return (
      <div>
        <IconButton className="iconButtonMedia" size={"small"}>
          <AttachmentIcon />
        </IconButton>
        <IconButton className="iconButtonMedia" size={"small"}>
          <PlayCircleOutlineIcon />
        </IconButton>
        <IconButton className="iconButtonMedia" size={"small"}>
          <HeadphonesIcon />
        </IconButton>
      </div>
    );
  };

  const allDriverColumns = [
    { field: "direction", headerName: "Direction", width: 110 },
    {
      field: "media",
      headerName: "Media",
      width: 110,
      renderCell: renderMedia,
    },
    {
      field: "messageText",
      headerName: "Message",
      width: 300,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word", lineHeight: 1.5 }}>
          {params.value}
        </div>
      ),
    },
    { field: "jobID", headerName: "JobID", width: 90, renderCell: renderJobID },
    {
      field: "drNum",
      headerName: "Driver#",
      width: 90,
      renderCell: renderDrNum,
    },
    { field: "datestampUTC", headerName: "Time", width: 180 },
  ];

  const handleOnCellClick = (params) => {
    console.log(params.field);
    if (params.field === "jobID") {
      let inxURL = "/jobdetail/" + params.value;
      window.open(inxURL, "_blank");
    }
    if (params.field === "drNum") {
      props.updateDriver(params.value);
    }
  };

  return (
    <div className="darkDataGrid" style={{ width: "100%", height: "75vh" }}>
      <DataGridPro
        rows={allMessages}
        columns={allDriverColumns}
        checkboxSelection={false}
        disableColumnSelector={true}
        disableColumnMenu={true}
        onCellClick={handleOnCellClick}
        rowHeight={70} // Increased row height for spacing
        sx={{
          "& .MuiDataGrid-row:nth-of-type(even)": {
            backgroundColor: "#0D191E", // Light gray for even rows
          },
        }}
      />
    </div>
  );
};

export default MessageList;
