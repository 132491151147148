import * as React from 'react';
import Popover from '@mui/material/Popover';

const PIN_IMAGES = {
  'black_d': require('./imgs/trip_pin/pin_black_D.png'),
  'black_p': require('./imgs/trip_pin/pin_black_P.png'),
  'blue_d': require('./imgs/trip_pin/pin_blue_D.png'),
  'blue_p': require('./imgs/trip_pin/pin_blue_P.png'),
  'light_blue_d': require('./imgs/trip_pin/pin_lightblue_D.png'),
  'light_blue_p': require('./imgs/trip_pin/pin_lightblue_P.png'),
  'brown_d': require('./imgs/trip_pin/pin_brown_D.png'),
  'brown_p': require('./imgs/trip_pin/pin_brown_P.png'),
  'red_d': require('./imgs/trip_pin/pin_red_D.png'),
  'red_p': require('./imgs/trip_pin/pin_red_P.png'),
  'green_d': require('./imgs/trip_pin/pin_green_D.png'),
  'green_p': require('./imgs/trip_pin/pin_green_P.png'),
  'light_green_d': require('./imgs/trip_pin/pin_lightgreen_D.png'),
  'light_green_p': require('./imgs/trip_pin/pin_lightgreen_P.png'),
  'purple_d': require('./imgs/trip_pin/pin_purple_D.png'),
  'purple_p': require('./imgs/trip_pin/pin_purple_P.png'),
  'yellow_d': require('./imgs/trip_pin/pin_yellow_D.png'),
  'yellow_p': require('./imgs/trip_pin/pin_yellow_P.png'),
};

function TripPin({onClick, pin, json}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    // Memoize JSON processing
    const processedJson = React.useMemo(() => {
        if (!json) return '';
        const parsed = JSON.parse(json);
        
        return (
            <div style={{display:'flex', maxWidth:'400px', flexWrap:'wrap', fontSize:'13px'}}>
                <div style={{padding:'3px 6px'}}>           
                    {(pin.mapLabel.toUpperCase() === "D" || pin.mapLabel.toUpperCase() === "DD" || pin.mapLabel.toUpperCase() === "SD")? 
                        <b className='colorPrimary'>Cons </b> : <b className='colorPrimary'>Ships </b>}
                    {parsed[0].Suite}  {parsed[0].Address}
                </div>
                <div style={{padding:'3px 6px'}}><b className='colorPrimary'>JobID</b> {pin.jobID}</div>
                <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Svc</b> {parsed[0].service}</div> 
                <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Pcs/Wgt</b> {parsed[0].pieces}ps/{parsed[0].weight}lbs</div>
                <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Call in</b> {parsed[0]["Called In"]}</div>
                <div style={{padding:'3px 6px'}}><b className='colorPrimary'>DCT</b> {parsed[0]["DCT Time"]} </div>
                {parsed[0].del_inst && <div style={{padding:'3px 6px'}}>
                    <b className='colorPrimary'>Instructions </b>
                    <span>{parsed[0].del_inst}</span>
                </div>}
            </div>
        );
    }, [json, pin?.jobID]);

    const handlePopoverOpen = React.useCallback((event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }, []);
    
    const handlePopoverClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleClick = React.useCallback((event) => {
        event.stopPropagation();
        onClick?.(event);
    }, [onClick]);

    // Get pin image based on color and type
    const getPinImage = React.useMemo(() => {
        if (!pin) return PIN_IMAGES.blue_d;
        
        const color = pin.mapColor.toLowerCase().replace(' ', '_');
        const type = (pin.mapLabel.toLowerCase() === 'sd' || 
                     pin.mapLabel.toLowerCase() === 'd' || 
                     pin.mapLabel.toLowerCase() === 'dd' || 
                     pin.mapLabel.toLowerCase() === 'rd') ? 'd' : 'p';
        
        return PIN_IMAGES[`${color}_${type}`] || PIN_IMAGES.blue_d;
    }, [pin?.mapColor, pin?.mapLabel]);

    return(
       <div 
            style={{display: "flex", justifyContent: "center"}} 
            onMouseEnter={handlePopoverOpen} 
            onMouseLeave={handlePopoverClose}
            onClick={handleClick}
        >
           <img
             src={getPinImage}
             style={{width:'60px', height:'auto', cursor: 'pointer'}}           
           />          
        <Popover
            className='popoverDark'
            sx={{
                pointerEvents: 'none',
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <div style={{padding:'10px'}}>
                {processedJson}
            </div>
        </Popover>    
        </div>
    );
}

export default React.memo(TripPin);